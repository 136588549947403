@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
$color1: #eeeeffff;
$color2: #7f7cafff;
$color3: #9fb4c7ff;
$color4: #28587bff;
$color5: #9fb798ff;

@mixin desktop {
  @media (min-device-width: 1224px) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

html,
body {
  font-family: 'DM Sans', sans-serif;
  // max-width: 1600px;
  width: 100%;
  height: 100%;
  position: relative;
}

#header {
  // font-size: 1.3em;
  // margin-bottom: 2em;
  width: 98%;
  margin: 0 auto;
  margin-top: 1em;
  background-color: $color4;
  box-shadow: 0px 3px 10px 5px $color3;
  text-align: center;
  padding-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  // padding-top: 0.2em;
  h1 {
    font-family: 'Playfair Display', serif;
    margin: 0;
    font-size: 4em;
  }
  h3 {
    font-size: 1.5em;
    margin: 0;
  }
}
.buttons {
  width: 100%;
  max-width: 1400px;
  gap: 20em;
  text-align: center;
  margin: 2em auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  button {
    // transform: rotate(-20deg);
    box-shadow: 0px 1px 10px 1px $color4;
    font-family: 'DM Sans', sans-serif;
    // text-decoration: underline;
    // font-family: "Playfair Display", serif;
    // text-transform: uppercase;
    color: $color4;
    font-size: 2em;
    font-weight: 500;
    background-color: white;
    border: 2px solid $color5;
    border-radius: 2px;
    margin: -1.5em auto;
    height: 2em;
    padding: 0 2em;
    cursor: pointer;
    outline: none;
    &:hover {
      box-shadow: 0px 1px 5px 1px $color4;
      border: 2px solid $color5;
      color: $color1;
      background-color: $color4;
    }
  }
}
.nav-title {
  margin-top: 0.5em;
  color: $color1;
}

#footer {
  box-shadow: 0px 3px 10px 5px $color4;
  display: grid;
  gap: 0em;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: $color1;
  color: $color4;
  padding: 2em;
  p {
    margin: 0;
  }
  .footer-section {
    font-size: 1.3em;
    text-align: center;
    padding: 2em 0;
  }
}
.content {
  color: $color4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  max-width: 1400px;
  gap: 4em;
  margin: 0 auto;
  margin-top: 4em;
  text-align: justify;
}
.content {
  .about-text {
    box-shadow: 0px 1px 10px 1px $color3;
    text-align: justify;
    padding: 2em;
    padding-top: 0;
    h1 {
      padding-top: 0.5em;
      margin-top: 0;
      text-align: center;
    }
  }
}
.background-container {
  grid-column-start: 1;
  grid-column-end: 3;
  color: $color4;
  text-align: justify;
  .background-text {
    padding: 1em;
    padding-top: 0;
    text-align: justify;
    h1 {
      // margin-top: 0;
      text-align: center;
    }
  }
  @include desktop {
    box-shadow: 0px 1px 10px 1px $color3;
    background-color: rgb(250, 250, 250);
    width: 100%;
    margin-top: 1.5em;
    h1 {
      margin-top: 0;
      padding-top: 1.5em;
      text-align: center;
    }
  }
}
.background-text {
  @include desktop {
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    margin-bottom: 4em;
  }
}
.home-image-container {
  @include desktop {
    height: 500px;
    overflow: hidden;
  }
}
.home-image {
  position: relative;
  width: 100%;
}
.services {
  box-shadow: 0px 1px 10px 1px $color3;
  background-color: rgb(250, 250, 250);
  user-select: none;
  width: 90%;
  max-width: 1400px;
  // min-height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 2em;
  padding-top: 0em;
  margin-bottom: 4em;
  margin-top: 4em;
}
.corporate-services {
  width: 90%;
  margin: 0 auto;
}
.private-services {
  width: 90%;
  margin: 0 auto;
}
.service-description {
  text-align: justify;
  height: 0;
  overflow-y: hidden;
  color: $color4;
}
.service-title {
  padding: 0;
  margin-bottom: 0;
  color: $color4;
  cursor: pointer;
  &:hover {
    color: $color5;
  }
}

.services-main-title {
  padding-top: 0.5em;
  margin-top: 0;
  text-align: center;
  border-bottom: 2px solid $color5;
  color: $color4;
}

.contact {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 5em;
  // border-top: 2px solid $color5;
  form {
    width: 100%;

    h1 {
      padding-top: 1em;
      border-bottom: 2px solid $color5;
      margin: 0 auto;
    }
    box-shadow: 0px 1px 10px 1px $color3;
    width: 100%;
    max-width: 600px;
    display: flex;
    margin: 0 auto;
    outline: none !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    p {
      font-size: 1.2em;
      color: $color4;
      width: 80%;
      margin: 0 auto;
      padding: 0.5em;
    }
    input {
      font-size: 1.2em;
      color: $color2;
      border: 2px solid $color5;
      width: 100%;
      margin-top: 0.5em;
      outline: none !important;
      height: 2em;
      border-color: $color5;
      padding: 0;
    }
    textarea {
      font-size: 1.2em;
      color: $color2;
      margin-top: 0.5em;
      outline: none !important;
      width: 100%;
      padding: 0;
      height: 10em;
      resize: none;
      border: 2px solid $color5;
    }
    button {
      font-family: 'DM Sans', sans-serif;
      box-shadow: 1px 1px 5px 1px $color3;
      font-size: 1.2em;
      color: $color4;
      display: block;
      outline: none !important;
      height: 2em;
      padding: 0 2em;
      margin: 0 auto;
      margin-bottom: 1em;
      background-color: white;
      border: 2px solid $color5;
      &:hover {
        color: $color1;
        background-color: $color4;
        cursor: pointer;
      }
    }
  }
}

.contact-title {
  text-align: center;
  color: $color4;
}
.placeholder-span {
  position: relative;
  top: 2em;
}
.placeholder-contact-span {
  position: relative;
  top: -2em;
}

// MOBILE
#header-mobile {
  background-color: $color4;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1.5em;
  h1 {
    font-family: 'Playfair Display', serif;
    margin: 0;
    font-size: 12vw;
  }
  h3 {
    font-size: 9vw;
    margin: 0;
  }
}
.buttons-mobile {
  width: 100%;
  button {
    text-align: center;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    color: $color4;
    font-size: 10vw;
    font-weight: 500;
    background-color: white;
    border: 0;
    border-bottom: 2px solid $color5;
    border-radius: 2px;
    padding: 0 2em;
    cursor: pointer;
    outline: none;
  }
}
.services-mobile {
  .services-main-title {
    margin-bottom: 1em;
    margin-top: 1em;
  }
  user-select: none;
  margin-top: 1em;
  margin-bottom: 3em;
}
#footer-mobile {
  color: $color4;
  padding: 2em;
  p {
    margin: 0;
  }
  .footer-section-mobile {
    font-size: 1.3em;
    text-align: center;
    padding: 1em 0;
  }
}
.content-mobile {
  color: $color4;
  text-align: justify;
  .about-text {
    padding: 1em;
    text-align: justify;
    h1 {
      text-align: center;
    }
  }
}

.contact-mobile {
  width: 95%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 5em;
  form {
    width: 100%;

    h1 {
      padding-top: 1em;
      border-bottom: 2px solid $color5;
      margin: 0 auto;
    }
    box-shadow: 0px 1px 10px 1px $color3;
    width: 100%;
    max-width: 600px;
    display: flex;
    margin: 0 auto;
    outline: none !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    p {
      font-size: 1.2em;
      color: $color4;
      width: 80%;
      margin: 0 auto;
      padding: 0.5em;
    }
    input {
      font-size: 1.2em;
      color: $color2;
      border: 2px solid $color5;
      width: 100%;
      margin-top: 0.5em;
      outline: none !important;
      height: 2em;
      border-color: $color5;
      padding: 0;
    }
    textarea {
      font-size: 1.2em;
      color: $color2;
      margin-top: 0.5em;
      outline: none !important;
      width: 100%;
      padding: 0;
      height: 10em;
      resize: none;
      border: 2px solid $color5;
    }
    button {
      font-family: 'DM Sans', sans-serif;
      box-shadow: 1px 1px 5px 1px $color3;
      font-size: 1.2em;
      color: $color4;
      display: block;
      outline: none !important;
      height: 2em;
      padding: 0 2em;
      margin: 0 auto;
      margin-bottom: 1em;
      background-color: white;
      border: 2px solid $color5;
      &:hover {
        color: $color1;
        background-color: $color4;
        cursor: pointer;
      }
    }
  }
}

.email-phone-link {
  font-family: inherit;
  color: inherit;
}

.footer-nav {
  @include desktop {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

#link-mrtracer {
  color: $color4;
}
