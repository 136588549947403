@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap");
html,
body {
  font-family: 'DM Sans', sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
}

#header {
  width: 98%;
  margin: 0 auto;
  margin-top: 1em;
  background-color: #28587bff;
  -webkit-box-shadow: 0px 3px 10px 5px #9fb4c7ff;
          box-shadow: 0px 3px 10px 5px #9fb4c7ff;
  text-align: center;
  padding-bottom: 1em;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

#header h1 {
  font-family: 'Playfair Display', serif;
  margin: 0;
  font-size: 4em;
}

#header h3 {
  font-size: 1.5em;
  margin: 0;
}

.buttons {
  width: 100%;
  max-width: 1400px;
  gap: 20em;
  text-align: center;
  margin: 2em auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

.buttons button {
  -webkit-box-shadow: 0px 1px 10px 1px #28587bff;
          box-shadow: 0px 1px 10px 1px #28587bff;
  font-family: 'DM Sans', sans-serif;
  color: #28587bff;
  font-size: 2em;
  font-weight: 500;
  background-color: white;
  border: 2px solid #9fb798ff;
  border-radius: 2px;
  margin: -1.5em auto;
  height: 2em;
  padding: 0 2em;
  cursor: pointer;
  outline: none;
}

.buttons button:hover {
  -webkit-box-shadow: 0px 1px 5px 1px #28587bff;
          box-shadow: 0px 1px 5px 1px #28587bff;
  border: 2px solid #9fb798ff;
  color: #eeeeffff;
  background-color: #28587bff;
}

.nav-title {
  margin-top: 0.5em;
  color: #eeeeffff;
}

#footer {
  -webkit-box-shadow: 0px 3px 10px 5px #28587bff;
          box-shadow: 0px 3px 10px 5px #28587bff;
  display: -ms-grid;
  display: grid;
  gap: 0em;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  background-color: #eeeeffff;
  color: #28587bff;
  padding: 2em;
}

#footer p {
  margin: 0;
}

#footer .footer-section {
  font-size: 1.3em;
  text-align: center;
  padding: 2em 0;
}

.content {
  color: #28587bff;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  width: 90%;
  max-width: 1400px;
  gap: 4em;
  margin: 0 auto;
  margin-top: 4em;
  text-align: justify;
}

.content .about-text {
  -webkit-box-shadow: 0px 1px 10px 1px #9fb4c7ff;
          box-shadow: 0px 1px 10px 1px #9fb4c7ff;
  text-align: justify;
  padding: 2em;
  padding-top: 0;
}

.content .about-text h1 {
  padding-top: 0.5em;
  margin-top: 0;
  text-align: center;
}

.background-container {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
  color: #28587bff;
  text-align: justify;
}

.background-container .background-text {
  padding: 1em;
  padding-top: 0;
  text-align: justify;
}

.background-container .background-text h1 {
  text-align: center;
}

@media (min-device-width: 1224px) {
  .background-container {
    -webkit-box-shadow: 0px 1px 10px 1px #9fb4c7ff;
            box-shadow: 0px 1px 10px 1px #9fb4c7ff;
    background-color: #fafafa;
    width: 100%;
    margin-top: 1.5em;
  }
  .background-container h1 {
    margin-top: 0;
    padding-top: 1.5em;
    text-align: center;
  }
}

@media (min-device-width: 1224px) {
  .background-text {
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    margin-bottom: 4em;
  }
}

@media (min-device-width: 1224px) {
  .home-image-container {
    height: 500px;
    overflow: hidden;
  }
}

.home-image {
  position: relative;
  width: 100%;
}

.services {
  -webkit-box-shadow: 0px 1px 10px 1px #9fb4c7ff;
          box-shadow: 0px 1px 10px 1px #9fb4c7ff;
  background-color: #fafafa;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  padding-bottom: 2em;
  padding-top: 0em;
  margin-bottom: 4em;
  margin-top: 4em;
}

.corporate-services {
  width: 90%;
  margin: 0 auto;
}

.private-services {
  width: 90%;
  margin: 0 auto;
}

.service-description {
  text-align: justify;
  height: 0;
  overflow-y: hidden;
  color: #28587bff;
}

.service-title {
  padding: 0;
  margin-bottom: 0;
  color: #28587bff;
  cursor: pointer;
}

.service-title:hover {
  color: #9fb798ff;
}

.services-main-title {
  padding-top: 0.5em;
  margin-top: 0;
  text-align: center;
  border-bottom: 2px solid #9fb798ff;
  color: #28587bff;
}

.contact {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 5em;
}

.contact form {
  width: 100%;
  -webkit-box-shadow: 0px 1px 10px 1px #9fb4c7ff;
          box-shadow: 0px 1px 10px 1px #9fb4c7ff;
  width: 100%;
  max-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  outline: none !important;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.contact form h1 {
  padding-top: 1em;
  border-bottom: 2px solid #9fb798ff;
  margin: 0 auto;
}

.contact form p {
  font-size: 1.2em;
  color: #28587bff;
  width: 80%;
  margin: 0 auto;
  padding: 0.5em;
}

.contact form input {
  font-size: 1.2em;
  color: #7f7cafff;
  border: 2px solid #9fb798ff;
  width: 100%;
  margin-top: 0.5em;
  outline: none !important;
  height: 2em;
  border-color: #9fb798ff;
  padding: 0;
}

.contact form textarea {
  font-size: 1.2em;
  color: #7f7cafff;
  margin-top: 0.5em;
  outline: none !important;
  width: 100%;
  padding: 0;
  height: 10em;
  resize: none;
  border: 2px solid #9fb798ff;
}

.contact form button {
  font-family: 'DM Sans', sans-serif;
  -webkit-box-shadow: 1px 1px 5px 1px #9fb4c7ff;
          box-shadow: 1px 1px 5px 1px #9fb4c7ff;
  font-size: 1.2em;
  color: #28587bff;
  display: block;
  outline: none !important;
  height: 2em;
  padding: 0 2em;
  margin: 0 auto;
  margin-bottom: 1em;
  background-color: white;
  border: 2px solid #9fb798ff;
}

.contact form button:hover {
  color: #eeeeffff;
  background-color: #28587bff;
  cursor: pointer;
}

.contact-title {
  text-align: center;
  color: #28587bff;
}

.placeholder-span {
  position: relative;
  top: 2em;
}

.placeholder-contact-span {
  position: relative;
  top: -2em;
}

#header-mobile {
  background-color: #28587bff;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1.5em;
}

#header-mobile h1 {
  font-family: 'Playfair Display', serif;
  margin: 0;
  font-size: 12vw;
}

#header-mobile h3 {
  font-size: 9vw;
  margin: 0;
}

.buttons-mobile {
  width: 100%;
}

.buttons-mobile button {
  text-align: center;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
  color: #28587bff;
  font-size: 10vw;
  font-weight: 500;
  background-color: white;
  border: 0;
  border-bottom: 2px solid #9fb798ff;
  border-radius: 2px;
  padding: 0 2em;
  cursor: pointer;
  outline: none;
}

.services-mobile {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 1em;
  margin-bottom: 3em;
}

.services-mobile .services-main-title {
  margin-bottom: 1em;
  margin-top: 1em;
}

#footer-mobile {
  color: #28587bff;
  padding: 2em;
}

#footer-mobile p {
  margin: 0;
}

#footer-mobile .footer-section-mobile {
  font-size: 1.3em;
  text-align: center;
  padding: 1em 0;
}

.content-mobile {
  color: #28587bff;
  text-align: justify;
}

.content-mobile .about-text {
  padding: 1em;
  text-align: justify;
}

.content-mobile .about-text h1 {
  text-align: center;
}

.contact-mobile {
  width: 95%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 5em;
}

.contact-mobile form {
  width: 100%;
  -webkit-box-shadow: 0px 1px 10px 1px #9fb4c7ff;
          box-shadow: 0px 1px 10px 1px #9fb4c7ff;
  width: 100%;
  max-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  outline: none !important;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.contact-mobile form h1 {
  padding-top: 1em;
  border-bottom: 2px solid #9fb798ff;
  margin: 0 auto;
}

.contact-mobile form p {
  font-size: 1.2em;
  color: #28587bff;
  width: 80%;
  margin: 0 auto;
  padding: 0.5em;
}

.contact-mobile form input {
  font-size: 1.2em;
  color: #7f7cafff;
  border: 2px solid #9fb798ff;
  width: 100%;
  margin-top: 0.5em;
  outline: none !important;
  height: 2em;
  border-color: #9fb798ff;
  padding: 0;
}

.contact-mobile form textarea {
  font-size: 1.2em;
  color: #7f7cafff;
  margin-top: 0.5em;
  outline: none !important;
  width: 100%;
  padding: 0;
  height: 10em;
  resize: none;
  border: 2px solid #9fb798ff;
}

.contact-mobile form button {
  font-family: 'DM Sans', sans-serif;
  -webkit-box-shadow: 1px 1px 5px 1px #9fb4c7ff;
          box-shadow: 1px 1px 5px 1px #9fb4c7ff;
  font-size: 1.2em;
  color: #28587bff;
  display: block;
  outline: none !important;
  height: 2em;
  padding: 0 2em;
  margin: 0 auto;
  margin-bottom: 1em;
  background-color: white;
  border: 2px solid #9fb798ff;
}

.contact-mobile form button:hover {
  color: #eeeeffff;
  background-color: #28587bff;
  cursor: pointer;
}

.email-phone-link {
  font-family: inherit;
  color: inherit;
}

@media (min-device-width: 1224px) {
  .footer-nav:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

#link-mrtracer {
  color: #28587bff;
}
